import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Box, Flex, DesktopContainer, MobileContainer, ResponsiveColRow1000 } from '../components/ui/Div'
import Button, { PillButton } from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import { colors, smallBoxShadow, shuffle } from '../components/ui/helpers.js'
import { urlToString, stringToUrl } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { H1, H2, H3, H4, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Text.js'
import OnlineIcon from '../components/ui/OnlineIcon'
import Image from '../components/ui/Image'
import Layout from '../components/layout'
import Helmet from '../components/Helmet'
import Header from '../components/Header'
import Footer from '../components/Footer'
import telephones from '../../constants/telephones'
import searches from '../../constants/searches'
import useCases from '../../constants/useCases'
import Breadcrumbs from '../components/Breadcrumbs'

const HeroContainer = styled(Flex)`
  position: relative;
  margin-bottom: 200px;
  background: rgb(2, 119, 181);
  background: linear-gradient(180deg, rgba(2, 119, 181, 1) 0%, rgba(1, 87, 133, 1) 100%);
  min-height: 600px;
  border-radius: 32px;
  flex-direction: column;
  padding-top: 120px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 100px;
  align-items: center;
  -webkit-box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
  @media (max-width: 700px) {
    min-height: 0px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 60px;
    padding-bottom: 130px;
    height: auto;
  }
`

const Container = styled(Flex)`
  background-color: white;
  padding: 24px;
  // -webkit-box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
  // box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  border-radius: 24px;
`

const PagePadding = styled(Flex)`
  flex-direction: column;
  padding-left: 100px;
  padding-right: 100px;
  @media (max-width: 700px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const FlagImage = styled.img`
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`
const Grid = styled(Flex)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const CountryContainer = styled(Flex)`
  background-color: white;
  border-radius: 24px;

  cursor: pointer;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  &&:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  }
`

const NumberGrid = styled(Flex)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 450px) {
    grid-gap: 16px;
    grid-template-columns: 1fr;
  }
`

const TelephoneContainer = styled(Flex)`
  position: relative;
  background-color: white;
  border-radius: 12px;
  padding: 8px;
  cursor: pointer;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  &:hover {
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 500px) {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
`

const Flag = ({ iso2, width, height }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width={width || '60px'} height={height || '60px'} />
}

const CountryPage = ({ pageContext: { country, iso2, countryCopy } }) => {
  console.log({ country, iso2, countryCopy }, telephones[country])
  return (
    <Layout bg="#F6F8F7">
      <Helmet
        title={`Receive SMS Online ${urlToString(country)} | SMS-ANDA`}
        htmlAttributes={{
          lang: 'en'
        }}
        description={`The internet's most extensive collection of ${urlToString(
          country
        )} temporary phone numbers. Receive SMS online for free with no ads or
            registration.`}
        url="https://sms-anda.com/"
      >
        <html lang="en" />
      </Helmet>
      <Header />

      <PagePadding alignItems="center">
        <HeroContainer maxWidth="1200px">
          <Breadcrumbs country={country} />
          <Flex>
            <DesktopContainer width="auto">
              <Flag iso2={countryToIso2[country]} width="100px" height="100px" />
            </DesktopContainer>
            <H1 textAlign="center" fontSize="75px" lineHeight="80px" fontSizem="40px" lineHeightm="44px" fontWeight="700" color="#FFFAFF" mb="32px">
              Receive SMS Online {urlToString(country)}
            </H1>
          </Flex>
          <H2 textAlign="center" color="rgb(204, 237, 255)" lineHeight="40px" fontSizem="20px" lineHeightm="24px">
            The internet's most extensive collection of {urlToString(country)} disposable phone numbers. Receive SMS online for free with no ads or
            registration.
          </H2>
        </HeroContainer>

        <ResponsiveColRow1000 zIndex={1} px="40px" pxm="0px" mt="-300px" mb="100px" justifyContent="space-between" maxWidth="1200px">
          <Flex flexDirection="column" mr="48px">
            <Flex flexDirection="column" mb="48px">
              <H3 mb="16px" mlm="16px" color="white">
                {urlToString(country)} SMS Numbers
              </H3>
              <NumberGrid>
                {telephones[country].map((telephone, i) => {
                  return (
                    <a key={telephone} href={`/${country}/${telephone}/`}>
                      <TelephoneContainer>
                        <Flex alignItems="center">
                          <Flag iso2={countryToIso2[country]} />
                          <Flex flexDirection="column" ml="16px">
                            <Text width="100%" fontSize="26px" textAlign="left" mr={2} mb="8px">
                              +{telephone}
                            </Text>
                          </Flex>
                          <OnlineIcon />
                        </Flex>
                      </TelephoneContainer>
                    </a>
                  )
                })}
              </NumberGrid>
            </Flex>

            {/* <Flex mb="60px" flexDirection="column">
              <H3 mb="16px">Why use a {iso2} temporary phone number?</H3>
              <Text>{countryCopy.why}</Text>
            </Flex> */}

            <H3 mb="16px">Other countries</H3>
            <Grid mb="60px" height="fit-content">
              {Object.keys(telephones).map((otherCountry) => {
                return (
                  <a key={otherCountry} href={`/${otherCountry}/`}>
                    <CountryContainer>
                      <Flex>
                        <Flag iso2={countryToIso2[otherCountry]} />
                        <Flex flexDirection="column" justifyContent="center">
                          <Text mb="8px" fontWeight="500" fontSize="20px" px="16px" mbtextAlign="center" A>
                            {urlToString(otherCountry)}
                          </Text>
                          <Text fontSize="16px" px="16px" mbtextAlign="center" A>
                            Active numbers: {telephones[otherCountry].length}
                          </Text>
                        </Flex>
                        <OnlineIcon />
                      </Flex>
                    </CountryContainer>
                  </a>
                )
              })}
            </Grid>
          </Flex>
          <Flex pt="40px" flexDirection="column" width="300px" minWidth="300px" widthm={'100%'} zIndex={1}>
            <Container flexWrap="wrap" mb="60px">
              <Text mb="16px" fontSize="18px" fontWeight="500">
                Our numbers can all be used for:
              </Text>
              <Flex flexWrap="wrap">
                {searches.map((search) => {
                  return (
                    <a href={`/${stringToUrl(search)}/`}>
                      <PillButton mb="16px" mr="16px" label={search} />
                    </a>
                  )
                })}
              </Flex>
            </Container>

            <a href={`/united-states/${telephones['united-states'][1]}`}>
              <Container flexWrap="wrap" mb="60px">
                <Text mb="16px" fontSize="18px" fontWeight="500">
                  Latest Phone Number:
                </Text>
                <Flex position="relative">
                  <Flex alignItems="center">
                    <Flag iso2={'US'} />
                    <Flex flexDirection="column" ml="16px">
                      <Text width="100%" fontSize="22px" textAlign="left" mr={2} mb="8px">
                        +{telephones['united-states'][1]}
                      </Text>
                      <Text>
                        Created: <Span color={colors.fontLight}>1 day ago</Span>
                      </Text>
                    </Flex>
                    <OnlineIcon />
                  </Flex>
                </Flex>
              </Container>
            </a>

            <Container flexWrap="wrap" mb="60px">
              <Text mb="16px" fontSize="18px" fontWeight="500">
                Services our numbers work for:
              </Text>
              <Flex flexWrap="wrap">
                {useCases.map((useCase) => {
                  return (
                    <a href={`/${stringToUrl(useCase)}/`}>
                      <PillButton mb="16px" mr="16px" label={useCase} />
                    </a>
                  )
                })}
              </Flex>
            </Container>

            <Container flexWrap="wrap">
              <Text mb="16px" fontSize="18px" fontWeight="500">
                Countries
              </Text>
              <Flex flexWrap="wrap">
                {Object.keys(telephones).map((country) => {
                  return (
                    <a href={`/${country}/`}>
                      <PillButton mb="16px" mr="16px" label={urlToString(country)} />
                    </a>
                  )
                })}
              </Flex>
            </Container>
          </Flex>
        </ResponsiveColRow1000>
      </PagePadding>
      <Footer iso2={'en'} />
    </Layout>
  )
}

export default CountryPage
